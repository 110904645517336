import React, { useState } from "react";

import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isEmpty } from "lodash";
import * as moment from "moment";
import styled from "styled-components";

import Error from "~/components/Errors/Error";
import { ButtonWrapper } from "~/components/Timeblocks/Containers";
import availabilityService from "~/utils/api/v1/availabilityService";
import timeBlockService from "~/utils/api/v1/timeblockService";
import { BlockedTimeBlock, TimeBlock } from "~/utils/interfaces/Timeblock";

moment.locale("es");

const Wrapper = styled.div`
  margin: 10px;
`;

const SelectWrapper = styled.div`
  .MuiFormControl-root {
    margin: 15px;
  }
`;

interface Props {
  onCancel: () => void;
  timeblocks: TimeBlock[];
  updateTimeblocks: (newTimeblocks: BlockedTimeBlock[]) => void;
  id: string;
  type?: "lab" | "nurse";
}

const AddBlockedTimeblock = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<object>({});
  const [selectedBeginHour, setSelectedBeginHour] = useState<string>(props.timeblocks[0].begin_hour);
  const [selectedEndHour, setSelectedEndHour] = useState<string>(props.timeblocks[0].end_hour);
  const [date, setDate] = useState<moment.Moment>(moment());

  const selectedTimeBlocksIds = (): Array<string> => {
    const selectedDay = date.locale("en").format("dddd").toLowerCase();
    return props.timeblocks.reduce((reducer: string[], timeblock: TimeBlock) => {
      if (timeblock.day === selectedDay) {
        if (timeblock.begin_hour >= selectedBeginHour && timeblock.end_hour <= selectedEndHour) {
          reducer.push(timeblock.id);
        }
      }
      return reducer;
    }, []);
  };

  const handleAcceptButton = async (): Promise<void> => {
    setLoading(true);
    setError({});
    try {
      const selectedIds = selectedTimeBlocksIds();
      let newBlockedTimeblocks;
      if (props.type === "lab") {
        newBlockedTimeblocks = await Promise.all(
          selectedIds.map((id: string) => {
            return availabilityService.labService.blockTimeBlock(props.id, id, date.format("YYYY-MM-DD"));
          }),
        );
      } else {
        newBlockedTimeblocks = await Promise.all(
          selectedIds.map(async (id: string) => {
            return timeBlockService.blockTimeBlock(id, {
              date: date.format("YYYY-MM-DD"),
              nurse_id: props.id,
            });
          }),
        );
      }
      // iterate over all datas in promises return and keep the longest one
      let latestData;
      const longestData = 0;
      for (let i = 0; i < newBlockedTimeblocks.length; i++) {
        if (newBlockedTimeblocks[i].data.data.length > longestData) {
          latestData = newBlockedTimeblocks[i].data.data;
        }
      }
      props.updateTimeblocks(latestData);
    } catch (err) {
      setError({ error: `${err}` });
    }

    setLoading(false);
  };

  return (
    <Wrapper>
      <h4>Bloquear disponibilidad</h4>

      <SelectWrapper>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="es"
        >
          <KeyboardDatePicker
            variant="inline"
            format="LL"
            margin="normal"
            label="Fecha inicio"
            value={date}
            autoOk
            disablePast={true}
            onChange={(date: moment.Moment) => {
              setDate(date);
            }}
          />
        </MuiPickersUtilsProvider>
        <FormControl>
          <InputLabel>Inicio</InputLabel>
          <Select
            value={selectedBeginHour}
            onChange={(e) => {
              setSelectedBeginHour(e.target.value as string);
            }}
          >
            {props.timeblocks.map((timeblock: TimeBlock) => {
              // assume all days have the same possible timeblocks available
              if (timeblock.day === "monday") {
                return (
                  <MenuItem
                    key={timeblock.begin_hour}
                    value={timeblock.begin_hour}
                  >
                    {timeblock.begin_hour}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Término</InputLabel>
          <Select
            value={selectedEndHour}
            onChange={(e) => {
              setSelectedEndHour(e.target.value as string);
            }}
          >
            {props.timeblocks.map((timeblock: TimeBlock) => {
              // assume all days have the same possible timeblocks available
              if (timeblock.day === "monday") {
                return (
                  <MenuItem
                    key={timeblock.end_hour}
                    value={timeblock.end_hour}
                  >
                    {timeblock.end_hour}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </SelectWrapper>

      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAcceptButton}
          disabled={loading}
        >
          Agregar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={props.onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>
      </ButtonWrapper>
      {!isEmpty(error) && (
        <>
          <Error>{JSON.stringify(error)}</Error>
        </>
      )}
    </Wrapper>
  );
};

export default AddBlockedTimeblock;
